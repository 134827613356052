<template>
  <div class="bg">
    <div class="container">
      <div class="banner">
        <span>当前位置：<router-link to="/" style="color:#aaa">众惠首页</router-link> &gt; </span>        
        <span style="color: #6b6b6b">活动列表</span>
      </div>
      <div class="middle">
        <div class="list">
          <router-link to="/information" class="btn">热门资讯</router-link>
          <router-link to="/fundSchool" class="btn">基金学堂</router-link>
          <div class="active btn">活动列表</div>
        </div>
        <div class="carousel">
          <swiper ref="mySwiper" class="swiper swiper-wrapper" :options="swiperOption">
            <swiper-slide v-for="item in bannerData" :key="item.id">
              <router-link :to="'/detail?type=activeList&infoId='+item.eventId" class="btn">
                <img :src="item.eventPic" />
              </router-link>
            </swiper-slide>
            <div slot="pagination" class="swiper-pagination" />
          </swiper>
        </div>
        <h3 style="margin: 24px 0; font-size: 20px; font-weight: 700">活动列表</h3>
        <div class="content">
          <div v-for="(item,index) in mydata" :key="index" class="box">
            <img
              :src="item.eventPic"
              style="width: 500px; height: 165px; border-radius: 10px; margin: 20px;"
            />
            <div class="list-info">
              <router-link
                :to="'/detail?type=activeList&infoId='+item.eventId"
                class="box-h2"
              >{{ item.eventName }}</router-link>
              <div class="mycontent">{{ item.eventSlogan }}</div>
              <div class="box-bottom">
                <div class="bottom-hot">
                  <div class="hot iconfont box-bottom-img1">&#xe678;</div>
                  <div>
                    <span
                      style="font-size: 14px"
                    >{{ item.eventStartTime&&item.eventStartTime.slice(0,10) + ' - ' + item.eventEndTime.slice(0,10) }}</span>
                    <span style="display: block; font-size: 12px; color: #8691a8">活动时间</span>
                  </div>
                </div>
                <router-link :to="'/detail?type=activeList&infoId='+item.eventId">
                  <span class="iconfont arrowright">&#xe667;</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination
        v-if="total > 0"
        :total="total"
        :page.sync="pageNum"
        :limit.sync="pageSize"
        :layout="layout"
        @pagination="getList"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.bg {
  background-color: #f3f4f6;
  margin-bottom: -30px;
}
.container {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
  position: relative;
}
.banner {
  height: 16px;
  width: 248px;
  text-align: left;
  color: #a5a5a5;
  font-size: 14px;
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 16px;
}
.middle {
  height: 100%;
  background-color: #fff;
  margin: 0 auto;
  padding: 40px;
}
.list {
  display: flex;
  margin-bottom: 40px;
}
.btn {
  /* height: 50px; */
  width: 124px;
  border: 1px solid #fff;
  font-size: 18px;
  color: #a5a5a5;
  text-align: center;
  line-height: 50px;
  margin-right: 24px;
  img {
    width: 100%;
  }
}
.active {
  border: 1px solid #ce9b63;
  border-radius: 26px;
  color: #ce9b63 !important;
  font-weight: 700;
  font-size: 18px;
}
.content {
  width: 1120px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.box {
  display: flex;
  flex-direction: column;
  width: 540px;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  margin: 24px 0;
}
.list-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hot {
  transform: scale(2);
  color: #ce9b63;
}
.box-h2 {
  font-size: 18px;
  line-height: 36px;
  margin-left: 40px;
  font-weight: bold;
  color: #25293d;
}
.mycontent {
  width: 460px;
  /* height: 21px; */
  font-size: 14px;
  line-height: 21px;
  color: #8691a8;
  margin-left: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.box-bottom {
  /* width: 460px; */
  /* height: 40px; */
  padding: 47px 50px 20px 48px;
  display: flex;
  justify-content: space-between;
}
.bottom-hot {
  display: flex;
  align-items: center;
  /* width: 121px; */
  /* height: 38px; */
}
.box-bottom-img1 {
  margin-right: 12px;
  /* height: 22px; */
}
.arrowright {
  color: #ce9b63;
  line-height: 40px;
}
.carousel {
  height: 438px;
  width: 1120px;
  border-radius: 10px;
  overflow: hidden;
}
.swiper-slide {
  width: 1120px;
  height: 438px;
  border-radius: 10px;
  display: block;
  overflow: hidden;
}
</style>

<script>
import { eventList } from '@/api/activeList'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      mydata: [],
      layout: 'prev, pager, next,sizes',
      total: 10,
      pageNum: 1,
      pageSize: 10,
      bannerData: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      eventList({ pageNum: this.pageNum, pageSize: this.pageSize }).then(
        (res) => {
          if (res.code === 200) {
            this.mydata = res.data.data
            this.pageTotal = res.data.total
            res.data.data.forEach((r, index) => {
              this.bannerData.push({
                eventPic: r.eventPic,
                eventId: r.eventId,
                id: r.eventId + index
              })
            })
          }
        }
      )
    }
  }
}
</script>